<template>
  <div class="serviceCase">
    <div class="topOne">
      <div class="topTitle">
        <div>咨询与服务类</div>
      </div>
      <div class="topContent">
        <div>广州赛马场信息咨询与服务</div>
        <div>深圳世界大学生运动会官方报告竞赛部分</div>
        <div>广州市公共体育场馆管理服务水平评价体系</div>
        <div>广州市体育局市属亚运场馆赛后利用培训</div>
        <div>广州市游艇产业规划</div>
        <div>广州市网球产业规划</div>
        <div>广州市马术项目发展规划</div>
        <div>广州市第六次全国体育场地普查</div>
        <div>湖北省利川市体育公园及房地产建设项目</div>
      </div>
    </div>
    <div class="topOne">
      <div class="topTitle">
        <div>设计与开发类</div>
      </div>
      <div class="topContent">
        <div>广州市青少年运动会信息管理系统</div>
        <div>缅甸东南亚运动会竞赛报名系统</div>
        <div>天津东亚运动会竞赛报名系统</div>
        <div>南京青奥会官方成绩管理系统</div>
        <div>广东省体育医院信息管理系统</div>
        <div>广州市国民体质监测系统</div>
        <div>体质健康检测评估系统</div>
        <div>广东省第八届老年人运动会信息管理系统</div>
        <div>广州市训练竞赛信息管理系统</div>
        <div>广州市群众体育信息管理系统</div>
        <div>广州 10 公里路跑赛官方网站与报名管理系统</div>
      </div>
    </div>
    <div class="topOne">
      <div class="topTitle">
        <div>体育器材类</div>
      </div>
      <div class="topContent">
        <div>华南师范大学</div>
        <div>暨南大学</div>
        <div>广州市海珠区体育局</div>
        <div>广东第二师范学院</div>
        <div>广州市体育局</div>
        <div>广州市海珠区体育发展中心</div>
        <div>广州市越秀区体育局</div>
      </div>
    </div>
    <div class="topOne">
      <div class="topTitle">
        <div>合作单位</div>
      </div>
      <div class="imgBox">
        <div class="imgOne" v-for="(item, index) in unitData" :key="index">
          <div class="img_box" v-if="item.url">
            <img :src="item.url" />
          </div>
          <div class="imgText">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="topOne">
      <div class="topTitle">
        <div>参与项目</div>
      </div>
      <div class="imgBox">
        <div class="imgOne" v-for="(item, index) in projectData" :key="index">
          <div class="img_box">
            <img :src="item.url" />
          </div>
          <div class="imgText">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      unitData: [
        { url: require("@/assets/image/unit/logo4.jpg"), name: "国家体育总局" },
        { url: require("@/assets/image/unit/logo3.png"), name: "广东省体育局" },
        {
          url: require("@/assets/image/unit/logo8.jpg"),
          name: "广东省奥林匹克体育中心",
        },
        { url: require("@/assets/image/unit/logo1.png"), name: "广州市体育局" },
        { url: require("@/assets/image/unit/logo9.png"), name: "东莞市体育局" },
        { url: require("@/assets/image/unit/logo5.jpg"), name: "香港赛马会" },
        {
          url: require("@/assets/image/unit/logo10.png"),
          name: "广东省体育场馆协会",
        },
        {
          url: require("@/assets/image/unit/logo6.jpg"),
          name: "广东省老年人体育协会",
        },
        {
          url: require("@/assets/image/unit/logo7.png"),
          name: "广东省武术协会",
        },
        {
          url: require("@/assets/image/unit/logo11.png"),
          name: "广东省马术协会",
        },
        {
          url: require("@/assets/image/unit/logo12.png"),
          name: "广东省足球协会",
        },
        {
          url: require("@/assets/image/unit/logo13.png"),
          name: "广东省手球协会",
        },
        {
          url: require("@/assets/image/unit/logo14.png"),
          name: "广东省体育基金会",
        },
        {
          url: require("@/assets/image/unit/logo15.png"),
          name: "广东省羽毛球协会",
        },
        // {
        //   url: require("@/assets/image/unit/logo2.png"),
        //   name: "广州市政府系统培训中心",
        // },
        {
          url: "",
          name: "广东省体育科学研究所",
        },
        {
          url: "",
          name: "广东省黄村体育训练中心",
        },
        {
          url: "",
          name: "广东省二沙体育训练中心",
        },
        {
          url: "",
          name: "广东省场馆器材中心",
        },
        {
          url: "",
          name: "区级文广旅体局",
        },
        {
          url: "",
          name: "广东省户外运动协会",
        },
        {
          url: "",
          name: "广东省柔道协会",
        },
      ],
      projectData: [
        {
          url: require("@/assets/image/project/logo1.png"),
          name: "2010年广州亚运会",
        },
        {
          url: require("@/assets/image/project/logo2.jpg"),
          name: "2011年深圳世界大学生运运会",
        },
        {
          url: require("@/assets/image/project/logo3.jpg"),
          name: "2012年南阳全国农民运动会",
        },
        {
          url: require("@/assets/image/project/logo4.png"),
          name: "2013年天津东亚运动会",
        },
        {
          url: require("@/assets/image/project/logo5.jpg"),
          name: "2013年东南亚运动会",
        },
        {
          url: require("@/assets/image/project/logo6.png"),
          name: "2014年广州市第十六届青少年运动会",
        },
        {
          url: require("@/assets/image/project/logo7.jpg"),
          name: "2014年广东省第八届老年人运动会",
        },
        {
          url: require("@/assets/image/project/logo8.jpg"),
          name: "2014年南京青年奥运会",
        },
        {
          url: require("@/assets/image/project/logo9.png"),
          name: "2014年世界汽车飘移系列赛广州大奖赛",
        },
        {
          url: require("@/assets/image/project/logo10.jpg"),
          name: "第六次全国体育场地普查",
        },

        {
          url: require("@/assets/image/project/logo11.png"),
          name: "2021年广州青少年运动会",
        },
        {
          url: require("@/assets/image/project/logo12.jpg"),
          name: "2021年江西省第十六届运动会",
        },
        {
          url: require("@/assets/image/project/logo13.png"),
          name: "2021年汕头亚洲青年运动会",
        },
        {
          url: require("@/assets/image/project/logo14.png"),
          name: "2022年杭州亚洲运动会",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.serviceCase {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 60px;
  .topOne {
    width: 100%;
    .imgBox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-top: 16px;
      .imgOne {
        width: 14.28%;
        padding: 30px 16px;
        box-sizing: border-box;
        text-align: center;
        .img_box {
          height: 120px;
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-height: 100%;
          }
        }
        .imgText {
          text-align: center;
          line-height: 24px;
        }
      }
    }
    .topTitle {
      width: 230px;
      height: 34px;
      line-height: 34px;
      color: #ffffff;
      text-align: center;
      background-color: #1e50ae;
      font-size: 18px;
      font-weight: bold;
      margin: 60px auto 26px;
      transform: skewX(-45deg);
      box-shadow: 18px 10px 6px 2px rgba(30, 80, 174, 0.1);
      div {
        transform: skewX(45deg);
      }
    }
    .topContent {
      width: 100%;
      line-height: 38px;
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      div {
        position: relative;
        width: 33.33%;
        &::after {
          position: absolute;
          content: "";
          width: 6px;
          height: 6px;
          background: #1e50ae;
          transform: rotate(45deg);
          left: -18px;
          top: 40%;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .serviceCase {
    width: 100%;
    .topOne {
      .topTitle {
        width: 180px;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        margin: 20px auto 0;
      }
      .topContent {
        display: block;
        padding: 20px 0 20px 40px;
        div {
          width: 100%;
        }
      }
      .imgBox {
        .imgOne {
          width: 50%;
          padding: 16px;
          .img_box {
            height: 100px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              max-height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>